import React from 'react'
import { Home, RssFeed,Share,Category,CardMembership, Flag } from '@material-ui/icons'
import {colors} from '../helpers/colors'

export const AdminSidebarData = [
    {
        title: "Home",
        icon: <Home style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/homepage",
    },
    {
        title: "Blogs",
        icon: <RssFeed style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/blogs",
    },
    {
        title: "Social Media",
        icon: <Share style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/socialMedia",
    },
    {
        title: "Subscription Types",
        icon: <CardMembership style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/subscriptionTypes",
    },
    {
        title: "Categories",
        icon: <Category style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/categories",
    },
    {
        title: "Countries",
        icon: <Flag style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/countries",
    },
    {
        title: "Governorates",
        icon: <Flag style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/governorates",
    },
    {
        title: "Regions",
        icon: <Flag style={{ fill: colors.primaryColor, transform: "scale(1.2)" }} size="100px" />,
        link: "/admin/regions",
    },
]