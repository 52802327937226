import React, { useState, useEffect } from "react"
import MUIDataTable from "mui-datatables"
import axios from 'axios'
import { toast } from 'react-toastify'
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import AddIcon from "@material-ui/icons/Add"
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@material-ui/core"
import Swal from 'sweetalert2'
import AdminLayout from './AdminLayout'
import { generateForm } from '../../helpers/functions'
import SingleCustomAutocomplete from '../../Components/CustomAutocomplete/SingleCustomAutocomplete'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const AdminRegions = ({ children, match, history }) => {

  const [governorates, setGovernorates] = useState([])
  const [data, setData] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    governorate_id: "",
    id: -1
  })
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [saveIsLoading, setSaveIsLoading] = useState(false)

  const getData = () => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_API}/admin/regions/get`, {
    })
      .then(function (response) {
        setData(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  const getGovernorates = () => {
    axios.get(`${process.env.REACT_APP_API}/admin/governorates/get`, {
    })
      .then(function (response) {
        setGovernorates(response.data)
      })
      .catch(function (error) {
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  useEffect(() => {
    getData()
    getGovernorates()
  }, [])

  const addForm = () => {
    setVisible(!visible)
    resetForm()
  }

  const updateFrom = (meta) => {
    const id = meta.rowData[0]
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/regions/getById/${id}`,
    })
      .then((response) => {
        setFormData({
          name: response.data.name,
          country_id: response.data.country_id,
          id: id
        })
        setVisible(!visible)
      })
      .catch((error) => {
        toast.error(`Error`)
      })
  }

  const saveForm = () => {
    setSaveIsLoading(true)
    if (formData.id > 0) {
      const submitFormData = generateForm(formData)
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/regions/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`)
          setSaveIsLoading(false)
          setVisible(!visible)
          getData()
        })
        .catch((error) => {
          setSaveIsLoading(false)
          toast.error(`Error While Saving`)
        })
    }
    else {
      const submitFormData = generateForm(formData)
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/regions/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`)
          setSaveIsLoading(false)
          setVisible(!visible)
          getData()
        })
        .catch((error) => {
          setSaveIsLoading(false)
          toast.error(`Error While Saving`)
        })
    }
  }

  const deleteForm = (meta) => {
    const id = meta.rowData[0]
    Swal.fire({
      title: 'Please Confirm Your Action ?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/regions/delete`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`)
            getData()
          })
          .catch((error) => {
            toast.error(`Error`)
          })
      }
    })
  }


  const handleInputChange = (e) => {
    const { id, name, value, checked, type } = e.target
    setFormData({
      ...formData,
      [id]: value,
    })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      governorate_id: "",
      id: -1
    })
  }

  const handleClose = () => {
    setVisible(false)
  }

  const customOnChange = (newValue, name) => {
    setFormData({
      ...formData,
      [name]: newValue,
    });
  }

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "governorate_name",
      label: "Governorate",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button className="btn btn-primary mx-1" onClick={() => { updateFrom(meta) }}>Update</button>
              <button className="btn btn-danger mx-1" onClick={() => { deleteForm(meta) }}>Delete</button>
            </div>
          )
        },
      },
    },
  ]

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (<span>
        <Tooltip title={"Add"}>
          <IconButton onClick={addForm}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </span>)
    },
    textLabels: {
      body: {
        noMatch: isLoading ?
          <CircularProgress /> :
          'There is no matching data to display',
      },
    }
  }


  return (
    <AdminLayout>
      <div className="container">
        <MUIDataTable
          title={"Regions"}
          data={data}
          columns={columns}
          options={options}
        />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{ zIndex: 2235 }}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>&times;</button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>

            </Toolbar>
          </AppBar>
          <div style={{ padding: "25px" }}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" className="form-control" id="name" onChange={handleInputChange} value={formData.name} />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <SingleCustomAutocomplete
                    filedName="governorate_id"
                    label="Governorate"
                    list={governorates}
                    value={formData.governorate_id}
                    listKey="id"
                    description="name"
                    customOnChange={customOnChange}
                  />
                </div>
              </div>
              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? <CircularProgress /> : <button className="btn btn-md btn-primary float-end" onClick={saveForm}>Save</button>}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  )
}
export default (AdminRegions)