import React from 'react';
import {
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import AdminSidebar from './AdminSidebar';
import {colors} from '../../helpers/colors';
import '../../assets/css/stylesAdmin.css';

const AdminLayout = ({ children }) => {
	return (
		<Container fluid>
			<Row className="flex-xl-nowrap">
				<Col  xs={ 12 } md={ 3 } lg={ 2 } style={{padding:0}}><AdminSidebar/></Col>
				<Col xs={ 12 } md={ 9 } lg={ 10 } style={{padding:0}}>
                    <>
                    <div className="w-100 p-3" style={{backgroundColor:colors.primaryColor,color:"#FFFFFF"}}>
                    <p>FIXASAP</p>
                    </div>
                    <div className="w-100 mt-3">
                    { children }
                    </div >
                    
                    </>
                    </Col>
			</Row>
		</Container>
	);

    // return (
    //     <div className="d-flex adminInterface" style={{height: '100vh'}}>
    //        <AdminSidebar match={match} />
    //         <div className="bodyContainer">
    //             {nav()}
    //             {children}
    //         </div>
    //     </div>
    // );
};

export default (AdminLayout);
