export const getDateStr = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })

}

export const getLandingRemainingTimeStr = () => {
  const currentDate = new Date()
  const TargetDate = new Date("Mar 01, 2022 00:00:00")
  const DifferenceInTime = TargetDate.getTime() - currentDate.getTime()
  var days = Math.floor(DifferenceInTime / (1000 * 60 * 60 * 24));
  var hours = Math.floor((DifferenceInTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((DifferenceInTime % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((DifferenceInTime % (1000 * 60)) / 1000);
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  }
}

export const generateForm = (data) => {
  const formData = new FormData()

  Object.keys(data).forEach((key, index) => {
    if (key === "id" && data[key] == -1){
      return
    }
    if (data[key] === true || data[key] === false){
      formData.append(key, data[key] === true ? 1 : 0)
      return  
    }
    formData.append(key, data[key])
  });
  return formData;
}
