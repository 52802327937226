import React, { useState, useEffect } from "react"
import FrontendNav from './FrontendNav'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"

const FrontendBlogsPage = ({ children, match, history }) => {
  //Search component ---------------END--------------
  // specifying your onload callback function

  const [adminBlogsData, setAdminBlogsData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const getData = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API}/noAuthApi/getAdminBlogs`, {
    })
      .then(function (response) {
        setAdminBlogsData(response.data)
        setLoading(false)

      })
      .catch(function (error) {
        setLoading(false)
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const openPlog = (id) => {
    navigate(`/blog/${id}`,{ state: { id: id } })
  }

  return (
    <FrontendNav>
      <div className="mt-5">
        <div className="container">
          <div className="row">
            {adminBlogsData.map((data) => {
              return (
                <div className="col-md-4" onClick={()=>{openPlog(data.id)}} style={{cursor:"pointer"}}>
                  <div className="card" >
                    <img src={`${process.env.REACT_APP_FILES_PATH}/adminBlogs/${data.img_name}`} className="card-img-top" style={{ width: "100%" }} />
                    <div className="card-body">
                      <h5 className="card-title">{data.title}</h5>
                      <p className="card-text">{data.short_description}</p>
                      <button className="btn btn-primary float-end">SEE MORE</button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </FrontendNav>
  )
}
export default (FrontendBlogsPage)