import React, { useState, useEffect } from "react"
import AdminLayout from './AdminLayout'
import axios from 'axios'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { FileUploader } from "react-drag-drop-files"
import { CircularProgress } from "@material-ui/core"

const fileTypes = ["JPG", "PNG"]

const AdminHomePage = ({ children, match, history }) => {

  const [adminHomeImagesData, setAdminHomeImagesData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [viewedFiles, setViewedFiles] = useState([])
  const [saveIsLoading, setSaveIsLoading] = useState(false)

  const saveForm = () => {
    setSaveIsLoading(true)
    const submitFormData = new FormData()
    files.map((fileData) => {
      submitFormData.append("files[]", fileData.file)
    })
    axios({
      method: "post",
      accept: "any",
      url: `${process.env.REACT_APP_API}/admin/homeImages/add`,
      data: submitFormData,
    })
      .then((response) => {
        //setCurrentId(response.data.serial)
        toast.success(`Inserted successfully`)
        setSaveIsLoading(false)
        getData()
        setFiles([])
        setViewedFiles([])
      })
      .catch((error) => {
        setSaveIsLoading(false)
        toast.error(`Error While Saving`)
      })

  }

  const getData = () => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_API}/admin/homeImages/get`, {
    })
      .then(function (response) {
        setIsLoading(false)
        setAdminHomeImagesData(response.data)

      })
      .catch(function (error) {
        setIsLoading(false)
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: 'Please Confirm Your Action ?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        icon: 'warning',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/homeImages/delete`,
            data: { id: id },
          })
            .then((response) => {
              getData()
              toast.success(`Success`)
            })
            .catch((error) => {
              toast.error(`Error`)
            })
        }
      })
    }
    else {
      let arr = files
      let arr2 = viewedFiles
      arr = arr.filter((v) => { return (v.id != id) })
      arr2 = arr2.filter((v) => { return (v.id != id) })
      setFiles(arr)
      setViewedFiles(arr2)
    }
  }

  const handleChangeFile = (file) => {
    const fileData = file
    if (fileData.type.match('image.*')) {
      const randomId = - (Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000)
      let temp2 = []
      files.forEach(element => {
        temp2.push(element)
      })
      temp2.push({ id: randomId, file: fileData })
      setFiles(temp2)

      const reader = new FileReader()
      reader.readAsDataURL(fileData)

      reader.onloadend = function (e) {
        const imagePath = reader.result
        let temp = []
        viewedFiles.forEach(element => {
          temp.push(element)
        })
        temp.push({ id: randomId, path: fileData, path: imagePath })
        setViewedFiles(temp)
      }
    }
    else {
      toast('Only Images Can be Uploaded', {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }
  return (
    <AdminLayout>
      <div className="container">
        <div className="row ">
        <div className={"col-sm-12"}>
            {saveIsLoading ? <CircularProgress /> : <button className="btn btn-md btn-primary float-end" onClick={saveForm}>Save</button>}
          </div>
          <FileUploader classes="w-100" handleChange={handleChangeFile} name="file" types={fileTypes}>
            <div className="w-100" style={{
              minHeight: "25vh",
              border: "2px dashed #ced4da",
              padding: "10px",
              textAlign: "center",
              marginTop: "1rem"
            }}>
              <p>DROP YOUR IMAGES HERE</p>
            </div>
          </FileUploader>
          <div className="row mt-3">
            {viewedFiles.map((imageData, index) => {
              return (
                <div className="col-sm-2" key={index}>
                  <img src={imageData.path} className="w-100 mt-3" />
                  <button className="btn btn-danger" style={{ position: "absolute" }} onClick={() => { removeImage(imageData.id) }}>X</button>
                </div>
              )
            })}
          </div>
          <hr className="mt-3"/>
          <h3 className="mt-3">Published</h3>
          {isLoading ?
            <CircularProgress />
            :
            <div className="row mt-3">
              {adminHomeImagesData.map((imageData, index) => {
                return (
                  <div className="col-sm-2" key={index}>
                    <img src={`${process.env.REACT_APP_FILES_PATH}/adminHome/${imageData.image_name}`} className="w-100 mt-3" />
                    <button className="btn btn-danger" style={{ position: "absolute" }} onClick={() => { removeImage(imageData.id) }}>X</button>
                  </div>
                )
              })}
            </div>
          }

        </div>
      </div>
    </AdminLayout>
  )
}
export default (AdminHomePage)