import React, { useEffect } from 'react'
import { createBrowserHistory } from "history"
import { HashRouter, Route, Routes as ReactRoutes } from "react-router-dom"
import axios from 'axios'
import cookie from "js-cookie"
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import FrontendHomePage from "./pages/frontend/FrontendHomePage"
import FrontendBlogsPage from "./pages/frontend/FrontendBlogsPage"
import FrontendBlogPage from "./pages/frontend/FrontendBlogPage"
import FrontendLandingPage from "./pages/frontend/FrontendLandingPage"
import AdminLogin from "./pages/backend/AdminLogin"
import AdminHomePage from "./pages/backend/AdminHomePage"
import AdminBlogs from "./pages/backend/AdminBlogs"
import AdminSocialMedia from "./pages/backend/AdminSocialMedia"
import AdminCategories from "./pages/backend/AdminCategories"
import AdminSubscriptionTypes from "./pages/backend/AdminSubscriptionTypes"
import AdminCountries from "./pages/backend/AdminCountries"
import AdminGovernorates from "./pages/backend/AdminGovernorates"
import AdminRegions from './pages/backend/AdminRegions'
import allActions from './actions/allActions'



const hist = createBrowserHistory()
const Routes = () => {
    const token = cookie.get("token")
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.currentUser)

    const loading = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
    )

    useEffect(() => {
        const vaildateUser = async () => {
            // verify a token symmetric
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
            await axios.get(`${process.env.REACT_APP_API}/auth/user-profile`)
                .then((res) => {
                    dispatch(allActions.userActions.setUser(res.data))
                })
        }
        if (token) {
            vaildateUser()
        }
    }, [token]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <HashRouter history={hist}>
            <React.Suspense fallback={loading} />
            <ToastContainer />
            <ReactRoutes>
                <Route path='*' element={<h1>Page Not Found</h1>} />
                <Route path="/" exact element={<FrontendLandingPage />} />
                <Route path="/home" exact element={<FrontendHomePage />} />
                <Route path="/blogs" exact element={<FrontendBlogsPage />} />
                <Route path="/blog/:id" exact element={<FrontendBlogPage />} />

                <Route path="/admin" exact element={<AdminLogin />} />
                {currentUser.loggedIn === true
                    ?
                    <>
                        <Route path="/admin/homepage" exact element={<AdminHomePage />} />
                        <Route path="/admin/blogs" exact element={<AdminBlogs />} />
                        <Route path="/admin/socialMedia" exact element={<AdminSocialMedia />} />
                        <Route path="/admin/categories" exact element={<AdminCategories />} />
                        <Route path="/admin/subscriptionTypes" exact element={<AdminSubscriptionTypes />} />
                        <Route path="/admin/countries" exact element={<AdminCountries />} />
                        <Route path="/admin/governorates" exact element={<AdminGovernorates />} />
                        <Route path="/admin/regions" exact element={<AdminRegions />} />
                        
                    </>
                    :
                    null
                }
            </ReactRoutes>
        </HashRouter>
    )
}

export default Routes