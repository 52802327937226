import React, { useState, useEffect } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import FrontendNav from './FrontendNav'
import { Carousel } from 'react-responsive-carousel'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"
import { getDateStr } from '../../helpers/functions'


const FrontendHomePage = ({ children, match, history }) => {
  //Search component ---------------END--------------
  // specifying your onload callback function

  const [adminHomeImagesData, setAdminHomeImagesData] = useState([])
  const [latestAdminBlogs, setLatestAdminBlogs] = useState([])

  const [loading, setLoading] = useState(false)
  const getData = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API}/noAuthApi/getHomeImages`, {
    })
      .then(function (response) {
        setAdminHomeImagesData(response.data)
        setLoading(false)

      })
      .catch(function (error) {
        setLoading(false)
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const getLatestAdminBlogs = () => {
    axios.get(`${process.env.REACT_APP_API}/noAuthApi/getLatestAdminBlogs`, {
    })
      .then(function (response) {
        setLatestAdminBlogs(response.data)
      })
      .catch(function (error) {
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  useEffect(() => {
    getData()
    getLatestAdminBlogs()
  }, [])

  const navigate = useNavigate()
  const openPlog = (id) => {
    navigate(`/blog/${id}`,{ state: { id: id } })
  }

  return (
    <FrontendNav>
      <div className="">
        {/* style={{height:"50vh"}} */}
        <div >
          {loading ?
            null
            :
            <Carousel dynamicHeight={false}
              showThumbs={false}
              swipeable={true}
              autoPlay={true}
              infiniteLoop={true}
              interval={5000}
            >
              {adminHomeImagesData.map((imageData,index) => {
                return (
                  <div key={index}>
                    <img src={`${process.env.REACT_APP_FILES_PATH}/adminHome/${imageData.image_name}`} style={{ height: "100vh" }} />
                  </div>
                )
              })}
            </Carousel>
          }
          <br />
          <br />
          <h2 className="text-center">LATEST BLOGS</h2>
          <hr />
          <div className="container">
          <div className="row">
            {latestAdminBlogs.map((data,index) => {
              return (
                <div className="col-md-4" key={index}>
                  <div className="card" >
                    <img src={`${process.env.REACT_APP_FILES_PATH}/adminBlogs/${data.img_name}`} className="card-img-top" style={{ width: "100%" }} />
                    <div className="card-body">
                      <h5 className="card-title">{data.title}</h5>
                      <p className="card-text">{data.short_description}</p>
                      <small className="text-muted float-start" style={{fontSize:"0.65em"}}>{getDateStr(data.created_at)}</small>
                      <button className="btn btn-primary float-end" onClick={()=>{openPlog(data.id)}}>SEE MORE</button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          </div>
        </div>
      </div>
    </FrontendNav>
  )
}
export default (FrontendHomePage)