import React, { useState, useEffect } from "react"
import MUIDataTable from "mui-datatables"
import axios from 'axios'
import { toast } from 'react-toastify'
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import AddIcon from "@material-ui/icons/Add"
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@material-ui/core"
import Swal from 'sweetalert2'
import { FileUploader } from "react-drag-drop-files"
import AdminLayout from './AdminLayout'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const fileTypes = ["JPG", "PNG"]
const AdminBlogs = ({ children, match, history }) => {

  const [adminBlogsData, setAdminBlogsData] = useState([])
  const [adminBlogsComments, setAdminBlogsComments] = useState([])
  const [formData, setFormData] = useState({
    title: "",
    short_description: "",
    long_description: "",
    id: -1
  })
  const [visible, setVisible] = useState(false)
  const [commentsModalVisible, setCommentsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [saveIsLoading, setSaveIsLoading] = useState(false)

  const getData = () => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_API}/admin/blogs/get`, {
    })
      .then(function (response) {
        setAdminBlogsData(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const addForm = () => {
    setVisible(!visible)
    resetForm()
  }

  const updateFrom = (meta) => {
    const id = meta.rowData[0]
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/blogs/getById/${id}`,
    })
      .then((response) => {
        setFormData({
          title: response.data.title,
          short_description: response.data.short_description,
          long_description: response.data.long_description,
          id: id
        })
        let temp = []
        response.data.images.forEach((element) => {
          temp.push({ id: element.id, path: `${process.env.REACT_APP_FILES_PATH}/adminBlogs/${element.image_name}` })
        })
        setViewedFiles(temp)
        setVisible(!visible)
      })
      .catch((error) => {
        toast.error(`Error`)
      })
  }

  const deleteForm = (meta) => {
    const id = meta.rowData[0]
    Swal.fire({
      title: 'Please Confirm Your Action ?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/blogs/delete`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`)
            getData()
          })
          .catch((error) => {
            toast.error(`Error`)
          })
      }
    })
  }

  const saveForm = () => {
    setSaveIsLoading(true)
    if (formData.id > 0) {
      const submitFormData = new FormData()
      submitFormData.append("title", formData.title)
      submitFormData.append("short_description", formData.short_description)
      submitFormData.append("long_description", formData.long_description)
      submitFormData.append("id", formData.id)
      files.map((fileData) => {
        submitFormData.append("files[]", fileData.file)
      })
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/blogs/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`)
          setSaveIsLoading(false)
          setVisible(!visible)
          getData()
        })
        .catch((error) => {
          setSaveIsLoading(false)
          toast.error(`Error While Saving`)
        })
    }
    else {
      const submitFormData = new FormData()
      submitFormData.append("title", formData.title)
      submitFormData.append("short_description", formData.short_description)
      submitFormData.append("long_description", formData.long_description)
      files.map((fileData) => {
        submitFormData.append("files[]", fileData.file)
      })
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/blogs/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`)
          setSaveIsLoading(false)
          setVisible(!visible)
          getData()
        })
        .catch((error) => {
          setSaveIsLoading(false)
          toast.error(`Error While Saving`)
        })
    }
  }

  const handleInputChange = (e) => {
    const { id, name, value, checked, type } = e.target
    setFormData({
      ...formData,
      [id]: value,
    })
  }

  const resetForm = () => {
    setFormData({
      title: "",
      short_description: "",
      long_description: "",
      id: -1
    })
    setViewedFiles([])
    setFiles([])
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleCloseCommentsModal = () => {
    setCommentsModalVisible(false)
  }

  const loadComments = (meta) => {
    const id = meta.rowData[0]
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/blogs/getCommentsByBlogId/${id}`,
    })
      .then((response) => {
        setAdminBlogsComments(response.data)
        setCommentsModalVisible(!commentsModalVisible)
      })
      .catch((error) => {
        toast.error(`Error`)
      })
  }

  const [files, setFiles] = useState([])
  const [viewedFiles, setViewedFiles] = useState([])
  const handleChangeFile = (file) => {
    const fileData = file
    if (fileData.type.match('image.*')) {
      const randomId = - (Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000)
      let temp2 = []
      files.forEach(element => {
        temp2.push(element)
      })
      temp2.push({ id: randomId, file: fileData })
      setFiles(temp2)

      const reader = new FileReader()
      reader.readAsDataURL(fileData)

      reader.onloadend = function (e) {
        const imagePath = reader.result
        let temp = []
        viewedFiles.forEach(element => {
          temp.push(element)
        })
        temp.push({ id: randomId, path: fileData, path: imagePath })
        setViewedFiles(temp)
      }
    }
    else {
      toast('Only Images Can be Uploaded', {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: 'Please Confirm Your Action ?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        icon: 'warning',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/blogs/deleteImage`,
            data: { id: id },
          })
            .then((response) => {
              let arr = files
              let arr2 = viewedFiles
              arr = arr.filter((v) => { return (v.id != id) })
              arr2 = arr2.filter((v) => { return (v.id != id) })
              setFiles(arr)
              setViewedFiles(arr2)
              toast.success(`Success`)
            })
            .catch((error) => {
              toast.error(`Error`)
            })
        }
      })
    }
    else {
      let arr = files
      let arr2 = viewedFiles
      arr = arr.filter((v) => { return (v.id != id) })
      arr2 = arr2.filter((v) => { return (v.id != id) })
      setFiles(arr)
      setViewedFiles(arr2)
    }

  }

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "title",
      label: "Title",
    },
    {
      name: "short_description",
      label: "Short Description",
  
    },
    {
      name: "long_description",
      label: "Long Description",
      options:{
        customBodyRender: (value, meta) => {
          return (
            <p title={value}>
            {value && value.substring(0,50)}
            </p>
          )
        }
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button className="btn btn-primary mx-1" onClick={() => { updateFrom(meta) }}>Update</button>
              <button className="btn btn-danger mx-1" onClick={() => { deleteForm(meta) }}>Delete</button>
              <button className="btn btn-warning mx-1" onClick={() => { loadComments(meta) }}>Comments</button>
            </div>
          )
        },
      },
    },
  ]

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (<span>
        <Tooltip title={"Add"}>
          <IconButton onClick={addForm}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </span>)
    },
    textLabels: {
      body: {
        noMatch: isLoading ?
          <CircularProgress /> :
          'There is no matching data to display',
      },
    }
  }

  const commentsColumns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "comment",
      label: "Comment",
    },
    {
      name: "full_name",
      label: "Full Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "created_at",
      label: "Date",
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta,updateValue) => {
          return (
            <div>
              <input className="form-check-input" type="checkbox" checked={value == 1 ? true : false} onClick={(e) => {const id = meta.rowData[0]; handleChangeCommentsActive(id,e,updateValue)}}/>
            </div>
          )
        },
      },
    },
  ]

  const commentsOptions = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isLoading ?
          <CircularProgress /> :
          'There is no matching data to display',
      },
    }
  }

  const handleChangeCommentsActive = (id,e,updateValue) => {

    // const requestOptions = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     serial:tabIndex,
    //     checkbox_type:checkbox_type,
    //     value:checked==true? 1: 0,
    //     profile:params.id
    //   }),
    // };
    const {checked} = e.target;
    updateValue(e.target.checked)
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API}/admin/blogs/updateCommentState`,
      data: { id: id,checked:checked },
    })
      .then((response) => {
        toast.success(`Success`)
      })
      .catch((error) => {
        toast.error(`Error`)
      })
  }

  return (
    <AdminLayout>
      <div className="container">
        <MUIDataTable
          title={"Blogs"}
          data={adminBlogsData}
          columns={columns}
          options={options}
        />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{ zIndex: 2235 }}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>&times;</button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>

            </Toolbar>
          </AppBar>
          <div style={{ padding: "25px" }}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="cover">Title</label>
                  <input type="text" className="form-control" id="title" onChange={handleInputChange} value={formData.title} />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="short_description">Short Description</label>
                  <input type="text" className="form-control" id="short_description" onChange={handleInputChange} value={formData.short_description} />
                </div>
              </div>
              <div className={"col-sm-12"}>
                <div className="form-group">
                  <label htmlFor="long_description">Long Description</label>
                  <textarea type="text" className="form-control" id="long_description" onChange={handleInputChange} value={formData.long_description} />
                </div>
              </div>
              <div className={"col-sm-12 mt-3"}>
                <FileUploader classes="w-100" handleChange={handleChangeFile} name="file" types={fileTypes}>
                  <div className="w-100" style={{
                    minHeight: "25vh",
                    border: "2px dashed #ced4da",
                    padding: "10px",
                    textAlign: "center",
                    marginTop: "1rem"
                  }}>
                    <p>DROP YOUR IMAGES HERE</p>
                  </div>
                </FileUploader>
                <div className="row mt-3">
                  {viewedFiles.map((imageData, index) => {
                    return (
                      <div className="col-sm-2" key={index}>
                        <img src={imageData.path} className="w-100 mt-3" />
                        <button className="btn btn-danger" style={{ position: "absolute" }} onClick={() => { removeImage(imageData.id) }}>X</button>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? <CircularProgress /> : <button className="btn btn-md btn-primary float-end" onClick={saveForm}>Save</button>}
              </div>
            </div>
          </div>

        </Dialog>

        <Dialog
          fullScreen
          open={commentsModalVisible}
          onClose={handleCloseCommentsModal}
          TransitionComponent={Transition}
          style={{ zIndex: 2235 }}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleCloseCommentsModal}>&times;</button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>

            </Toolbar>
          </AppBar>
          <div style={{ padding: "25px" }}>
              <MUIDataTable
              title={"Blogs"}
              data={adminBlogsComments}
              columns={commentsColumns}
              options={commentsOptions}
            />
          </div>

        </Dialog>
      </div>


    </AdminLayout>
  )
}
export default (AdminBlogs)