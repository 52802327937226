import React, { useState, useEffect } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Facebook, Instagram, Twitter, LinkedIn } from '@material-ui/icons'
import axios from "axios"
import FrontendNav from './FrontendNav'
import { getLandingRemainingTimeStr } from '../../helpers/functions'
import {colors} from '../../helpers/colors';



const FrontendLandingPage = ({ children, match, history }) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState([])
  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getLandingRemainingTimeStr())
    }, 1000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  useEffect(() => {
    const getSocialMediaLinks = () => {
      axios.get(`${process.env.REACT_APP_API}/noAuthApi/getSocialMediaLinks`, {
      })
        .then(function (response) {
          setSocialMediaLinks(response.data)

        })
        .catch(function (error) {
          console.log(error);
        })
    }
    getSocialMediaLinks()
  }, [])

  return (
    <FrontendNav>
      <div className="container">
        <div className="text-center">
          <div>
            <img
              src={`${process.env.REACT_APP_FILES_PATH}/images/logo.png`}
              alt="Services" style={{ height: "12rem" }}
            />
            <p style={{ color: "#000000", }}>PRO FIX PRO</p>
            <hr className="w-25" style={{
              margin: "0 auto",
              color: "#000000",
              height: "2px",
              opacity: "9"
            }} />
            <br />
            <br />
            <h3 style={{ color: colors.primaryColor }}>COMMING SOON</h3>
            <h6 style={{
              marginTop: "1rem",
              color: "#000000",
              fontSize: "0.6em",
              fontWeight: "550",
            }}>O U R &nbsp;&nbsp; W E B I S T E &nbsp;&nbsp; IS &nbsp;&nbsp; U N D E R &nbsp;&nbsp; C O R P O R A T E</h6>
            <br />
            <br />
            <br />
            <div className="d-flex justify-content-around w-50" style={{ margin: "0 auto" }}>
              <span style={{ color: "#000000" }}><span style={{ fontSize: "2.5em" }}>{remainingTime.days}</span> / DAYS</span>
              <span style={{ color: "#000000" }}><span style={{ fontSize: "2.5em" }}>{remainingTime.hours}</span> / HOURS</span>
              <span style={{ color: "#000000" }}><span style={{ fontSize: "2.5em" }}>{remainingTime.minutes}</span> / MINS</span>
              <span style={{ color: "#000000" }}><span style={{ fontSize: "2.5em" }}>{remainingTime.seconds}</span> / SEC</span>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="w-50" style={{ margin: "0 auto" }}>
              <h6>Follow Us For Updates</h6>
              <div className="d-flex justify-content-around w-25 mt-3" style={{ margin: "0 auto" }}>
                {socialMediaLinks.map(val => {
                  if (val.social_media_type === "facebook"){
                    return <a href="text-decoration-none" href={val.link} target="_blank" style={{ color: "#6B6B6B" }}>
                    <Facebook />
                  </a>
                  }
                  if (val.social_media_type === "twitter"){
                    return <a href="text-decoration-none" href={val.link} target="_blank" style={{ color: "#6B6B6B" }}>
                    <Twitter />
                  </a>
                  }
                  if (val.social_media_type === "instagram"){
                    return <a href="text-decoration-none" href={val.link} target="_blank" style={{ color: "#6B6B6B" }}>
                    <Instagram />
                  </a>
                  }
                  if (val.social_media_type === "linkedin"){
                    return <a href="text-decoration-none" href={val.link} target="_blank" style={{ color: "#6B6B6B" }}>
                    <LinkedIn />
                  </a>
                  }
                  
                })}
              </div>

            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

          </div>

        </div>

      </div>
    </FrontendNav>
  )
}
export default (FrontendLandingPage)