import React, { useState, useEffect } from "react";
import { Navbar, Nav } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import { CircularProgress } from "@material-ui/core";
import {colors} from '../../helpers/colors'

const FrontendNav = ({ children, match, history }) => {
  const [newsLetterEmail,setNewsLetterEmail] = useState("")
  const [saveIsLoading, setSaveIsLoading] = useState(false)
  const submitNewsLetterEmail = () => {
    setSaveIsLoading(true)
    if (newsLetterEmail) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/noAuthApi/addNewsLetterEmail`,
        data: {email: newsLetterEmail},
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Great Thank You For Registering`)
          setSaveIsLoading(false)
          setNewsLetterEmail("")
        })
        .catch((error) => {
          setSaveIsLoading(false)
          toast.error(`Error While Saving`)
        })
    }
    else {
      setSaveIsLoading(false)
      toast.error(`Please Fill All The Fields`)
    }
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="w-100"
      >
        <Navbar.Brand href="/#/home" className="ms-5">
          <img
            src={`${process.env.REACT_APP_FILES_PATH}/images/logo.png`}
            alt="Services" style={{ height: "9rem" }}
          />
          <span className="pt-3" style={{color:colors.primaryColor,fontSize:"1.8em","fontWeight":500}}>FIXASAP</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-md-auto w-100 me-5" style={{justifyContent:"flex-end"}}>
            <Nav.Link href="/#/home">Home</Nav.Link>
            <Nav.Link href="/#/blogs">Blogs</Nav.Link>
            {/* <Nav.Link href="/#/admin">Admin</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      
      {children}

      <footer className="mt-5 d-flex">
        <div className="container" style={{ paddingLeft: "5rem" }}>
          <div className="row pb-3 mb-3">
            <div className="col-sm mb-3">
              <h2 className="text-white p-0">Sign up to our newsletter !</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1" value={newsLetterEmail} onChange={(e)=>setNewsLetterEmail(e.target.value)} />
                {
                  saveIsLoading ? <CircularProgress/>
                  :
                  <button type="submit" className="btn btn-primary" onClick={submitNewsLetterEmail}>SUBMIT</button>
                }
                
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="col-6 col-md-3 ">
            <h5 className="text-uppercase">Useful Links</h5>
            <br />
            <ul className="list-unstyled">
              <li>
                <Nav.Link href="/#/home" style={{ color: "#FFF" }}>Home</Nav.Link>
              </li>
              <li>
                <Nav.Link href="/#/blogs" style={{ color: "#FFF" }}>Blogs</Nav.Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="lastDiv"><div className="container">© 2022 FIXEDASAP. By <a href="http://www.dsoft-lb.com/" target="_blank" className="text-white fw-bold">D S O F T</a></div></div>
    </>
  );
}
export default (FrontendNav)