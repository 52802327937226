import React, { useState, useEffect } from "react"
import FrontendNav from './FrontendNav'
import { Carousel } from 'react-responsive-carousel'
import { useLocation } from "react-router-dom"
import axios from 'axios'
import { toast } from 'react-toastify'
import { CircularProgress } from "@material-ui/core"
import { Person} from '@material-ui/icons'
import { getDateStr } from '../../helpers/functions'



const FrontendBlogPage = ({ children, match, history }) => {
  //Search component ---------------END--------------
  // specifying your onload callback function
  const [adminBlogData, setAdminBlogData] = useState({})
  const [adminBlogComment, setAdminBlogComment] = useState([])
  const [loading, setLoading] = useState(false)
  const [saveIsLoading, setSaveIsLoading] = useState(false)
  const { state } = useLocation();
  const { id } = state; // Read values passed on state
  const getData = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API}/noAuthApi/getAdminBlogData/${id}`, {
    })
      .then(function (response) {
        setAdminBlogData(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        toast('Error', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  const getAdminBlogComments = () => {
    axios.get(`${process.env.REACT_APP_API}/noAuthApi/getAdminBlogComment/${id}`, {
    })
      .then(function (response) {
        setAdminBlogComment(response.data)
      })
      .catch(function (error) {
        toast('Error Loading Comments', {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  useEffect(() => {
    getData()
    getAdminBlogComments()
  }, [])
  const [commentForm, setCommentForm] = useState({
    comment: "",
    full_name: "",
    email: "",
    blog_id: id
  })

  const handleInputChange = (e) => {
    const { id, name, value, checked, type } = e.target
    setCommentForm({
      ...commentForm,
      [id]: value,
    })
  }

  const saveForm = () => {
    setSaveIsLoading(true)
    if (commentForm.comment) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/noAuthApi/addAdminBlogComment`,
        data: commentForm,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Comment Placed successfully`)
          setSaveIsLoading(false)
          resetCommentForm()
        })
        .catch((error) => {
          setSaveIsLoading(false)
          toast.error(`Error While Saving`)
        })
    }
    else {
      setSaveIsLoading(false)
      toast.error(`Please Fill All The Fields`)
    }

  }

  const resetCommentForm = () => {
    setCommentForm({
      comment: "",
      full_name: "",
      email: "",
      blog_id: id
    })
  }

  return (
    <FrontendNav>
      <div className="mt-3">
        <div className="container">
          <div className="row">
            {loading ? <CircularProgress /> :
              <>
                <h2>{adminBlogData.title}</h2>
                <div className="col-md-12">
                  <Carousel dynamicHeight={false}
                    showThumbs={true}
                    swipeable={true}
                    infiniteLoop={true}
                    thumbWidth={100}
                  >
                    {adminBlogData && Object.keys(adminBlogData).length > 0 && adminBlogData.images.map((imageData, index) => {
                      return (
                        <div key={index}>
                          <img src={`${process.env.REACT_APP_FILES_PATH}/adminBlogs/${imageData.image_name}`} style={{ height: "70vh" }} />
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
                <div className="col-md-12">
                  <h4>{adminBlogData.short_description}</h4>
                </div>
                <div className="col-md-12">
                  <p>{adminBlogData.long_description ? adminBlogData.long_description : ""}</p>
                </div>
                <div className="col-md-12">
                  <br />
                  <h4>Comments</h4>
                  <hr />
                  <div className="col-md-6">
                    <div className="comment-widgets">
                      {adminBlogComment.length === 0 && <small>No Comments Yet</small>}
                      {adminBlogComment.map((element) => {
                        return (
                          <div className="d-flex flex-row comment-row mt-0">
                          <div className="p-2">
                            <Person/>
                            {/* <img src={`${process.env.REACT_APP_FILES_PATH}/images/logo.png`} alt="user" width="50" className="rounded-circle" /> */}
                          </div>
                          <div className="comment-text w-100">
                            <h6 className="font-medium">{element.full_name}</h6>
                            <span className="mb-2 d-block">{element.comment} </span>
                            <div className="comment-footer">
                              <small className="text-muted float-end text-small" style={{fontSize:"0.65em"}}>{getDateStr(element.created_at)}</small>
                            </div>
                          </div>
                        </div>
                        )
                      })}
                    </div>
                  </div>
                  <div>
                    <hr />
                    <h4>Leave A Comment</h4>
                    <div className="col-md-6">
                      <textarea className="form-control w-100" id="comment" placeholder={"Leave Your Comment Here"} onChange={handleInputChange} value={commentForm.comment} />
                      <br />
                      <div className="d-flex">
                        <input type="text" className="form-control w-50" id="email" placeholder={"Your Email"} onChange={handleInputChange} value={commentForm.email} />
                        <input type="text" className="form-control w-50" id="full_name" placeholder={"Your Full Name"} onChange={handleInputChange} value={commentForm.full_name} />
                      </div>
                      
                      <br />
                      {saveIsLoading ? <CircularProgress /> : <button className="btn btn-primary float-end" onClick={saveForm}>Place My Comment</button>}
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </FrontendNav>
  )
}
export default (FrontendBlogPage)